import { Col, Space, Badge, Button } from "antd";
import {
  EditIcon,
  SingleArrowRightIcon,
  commonStyles,
} from "@datwyler/mfe-shared-components";
import { SiteListHeader, SiteListItem, SiteListType } from "@types";
import { HeaderContainer, StyledRow } from "./styled";

const {
  StyledCard,
  Title18MediumPrimary,
  Body14RegularSecondary,
  Body14RegularTertiary,
  Body16MediumSecondary,
} = commonStyles;

const Header = ({
  name,
  country,
  latitude,
  longitude,
  setComponentToShow,
  intl,
}: SiteListHeader) => {
  return (
    <HeaderContainer>
      <Col span={24}>
        <Space align="center">
          <Badge
            status="success"
            styles={{ indicator: { width: 8, height: 8 } }}
          />
          <Title18MediumPrimary>{name}</Title18MediumPrimary>
          <Button
            type="text"
            icon={<EditIcon style={{ height: 16, width: 16 }} />}
            onClick={() => setComponentToShow("location-edit")}
          />
        </Space>
      </Col>
      <Col span={24} style={{ paddingLeft: 16 }}>
        <Body14RegularSecondary>
          {intl.formatMessage({ id: "location" })}:&nbsp;
        </Body14RegularSecondary>
        <Body14RegularTertiary>{country?.name}</Body14RegularTertiary>
      </Col>
      <Col span={24} style={{ paddingLeft: 16 }}>
        <Body14RegularSecondary>
          {intl.formatMessage({ id: "coordinates" })}:&nbsp;
        </Body14RegularSecondary>
        <Body14RegularTertiary>
          {latitude},&nbsp;{longitude}
        </Body14RegularTertiary>
      </Col>
    </HeaderContainer>
  );
};

const ListItem = ({
  site,
  setSelectedSite,
  setComponentToShow,
}: SiteListItem) => {
  return (
    <StyledRow
      onClick={() => {
        setSelectedSite(site);
        setComponentToShow("site-details");
      }}
    >
      <Col
        span={22}
        style={{ display: "flex", flexDirection: "column", gap: 4 }}
      >
        <div>
          <Body16MediumSecondary>{site?.name} </Body16MediumSecondary>
        </div>
        <div>
          <Body14RegularTertiary>{site?.url}</Body14RegularTertiary>
        </div>
      </Col>
      <Col span={2} style={{ alignSelf: "center", textAlign: "end" }}>
        <SingleArrowRightIcon style={{ height: 20, width: 20 }} />
      </Col>
    </StyledRow>
  );
};

export const SiteList = ({
  location,
  setSelectedSite,
  setComponentToShow,
  intl,
}: SiteListType) => {
  const sites = location.sites || [];
  return (
    <StyledCard
      style={{ width: 360, position: "absolute", left: 366, top: 70 }}
    >
      <Header
        {...location}
        intl={intl}
        setComponentToShow={setComponentToShow}
      />
      {sites.map((site, index) => (
        <ListItem
          key={index}
          site={site}
          setSelectedSite={setSelectedSite}
          setComponentToShow={setComponentToShow}
        />
      ))}
    </StyledCard>
  );
};

export default SiteList;
