import { useContext } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import { lodash as _, DarkModeContext } from "@datwyler/mfe-shared-components";
import { TenantIdProvider } from "@datwyler/shared-components";
import Locations from "@containers/locations";
import Attachments from "@containers/attachments";
import { ThemeContextType } from "@types";

const App = () => {
  const { theme } = useContext<ThemeContextType>(DarkModeContext);
  const location = useLocation();

  const tenantId = _.get(location, "state.tenantId");

  return (
    <TenantIdProvider tenantId={tenantId}>
      <ConfigProvider theme={theme}>
        <Routes>
          <Route
            path="/site/attachments"
            element={<Attachments tenantId={tenantId} />}
          />
          <Route path="/site" element={<Locations tenantId={tenantId} />} />
        </Routes>
      </ConfigProvider>
    </TenantIdProvider>
  );
};

export default App;
