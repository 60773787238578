/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useContext, useEffect } from "react";
import { Flex, Grid, Card, Row, Col } from "antd";
import {
  ApiContext,
  NotificationContext,
  useIntl,
  commonStyles,
} from "@datwyler/mfe-shared-components";
import AttachmentTable from "@components/AttachmentTable";
import AttachmentFilter from "@components/AttachmentFilter";
import AddAttachment from "@components/Attachment";
import Breadcrumbs from "@components/Breadcrumb";
import useAttachments from "@hooks/useAttachments";
import { downloadAttachment } from "@helpers";

const { Title } = commonStyles;
const { useBreakpoint } = Grid;

const Attachment = ({ tenantId }: { tenantId: string }) => {
  const breakpoints = useBreakpoint();
  const intl = useIntl();
  const { uri, apiInstance } = useContext<any>(ApiContext);
  const { openNotification } = useContext<any>(NotificationContext);
  const {
    attachments,
    locations,
    totalRows,
    rowsPerPage,
    pageNumber,
    deletedAttachment,
    setPageNumber,
    setRowsPerPage,
    setFilters,
    deleteAttachment,
  } = useAttachments(tenantId);

  useEffect(() => {
    if (deletedAttachment) {
      openNotification({
        type: "success",
        description: intl.formatMessage({ id: "attachment_deleted_success" }),
        placement: "topRight",
      });
    }
  }, [intl, deletedAttachment, openNotification]);

  const siteOptions = useMemo(() => {
    const siteList = locations.reduce((accum, current) => {
      return [...accum, ...(current.sites || [])];
    }, []);

    return siteList.map(({ name, id }) => ({ label: name, value: id }));
  }, [locations]);

  const handleAttachmentDownload = ({ raw }) => {
    downloadAttachment(raw, tenantId, uri, apiInstance, openNotification);
  };

  return (
    <Flex vertical gap={28} style={{ paddingBottom: 24 }}>
      <Breadcrumbs items={[{ title: "attachments" }]} />
      <Card>
        <Flex vertical gap={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={10}>
              <Title>{intl.formatMessage({ id: "attachments" })}</Title>
            </Col>
            <Col xs={24} sm={14}>
              <Flex justify={breakpoints.xs ? "flex-start" : "flex-end"}>
                <AttachmentFilter
                  setFilters={setFilters}
                  locations={locations}
                  setPageNumber={setPageNumber}
                  intl={intl}
                />
                <div>
                  <AddAttachment
                    tenantId={tenantId}
                    siteId={null}
                    siteOptions={siteOptions}
                    showSiteField={true}
                  />
                </div>
              </Flex>
            </Col>
          </Row>
          <AttachmentTable
            attachments={attachments}
            locations={locations}
            totalRows={totalRows}
            rowsPerPage={rowsPerPage}
            setPageNumber={setPageNumber}
            setRowsPerPage={setRowsPerPage}
            pageNumber={pageNumber}
            handleAttachmentDownload={handleAttachmentDownload}
            deleteAttachment={deleteAttachment}
            intl={intl}
          />
        </Flex>
      </Card>
    </Flex>
  );
};

export default Attachment;
