import { useEffect, useMemo, useContext, useState } from "react";
import { Form, Flex, Button, Divider } from "antd";

import {
  NotificationContext,
  useCountry,
  useDevice,
  useLocation,
  SingleArrowLeftIcon,
  lodash as _,
  useTheme,
  useIntl,
  commonStyles,
} from "@datwyler/mfe-shared-components";
import DeleteConfirmModal from "@components/DeleteConfirmModal";
import { transformCountryOption } from "@helpers";
import {
  EntityStatus,
  LocationCompType,
  LocationHeaderType,
  LocationFooterType,
} from "@types";
import LocationForm from "./Location";
import SiteForm from "./Sites";

const { StyledCard, Title18MediumPrimary, Button16Invert, Button16Primary } =
  commonStyles;

const Header = ({ setComponentToShow, mode, intl }: LocationHeaderType) => {
  return (
    <Flex style={{ padding: "16px 0", marginLeft: "-8px" }} align="center">
      <Button
        type="text"
        icon={<SingleArrowLeftIcon style={{ height: 24, width: 24 }} />}
        onClick={() => setComponentToShow(mode === "add" ? "" : "site-list")}
      />
      <Title18MediumPrimary>
        {intl.formatMessage({ id: "add_location" })}
      </Title18MediumPrimary>
    </Flex>
  );
};

const Footer = ({ mode, setShowDeleteConfirm, intl }: LocationFooterType) => {
  const theme = useTheme();
  return (
    <div
      style={{
        padding: "16px 0",
        position: "sticky",
        bottom: 0,
        backgroundColor: theme.datwylerSurface.SurfacePrimary,
      }}
    >
      {mode === "add" ? (
        <Button16Invert block htmlType="submit" type="primary">
          {intl.formatMessage({ id: "new_btn" })}
        </Button16Invert>
      ) : (
        <Flex justify="space-between" gap={16}>
          <Button16Primary
            onClick={() => setShowDeleteConfirm(true)}
            style={{ width: "100%" }}
          >
            {intl.formatMessage({ id: "delete_location_btn" })}
          </Button16Primary>
          <Button16Invert
            htmlType="submit"
            type="primary"
            style={{ width: "100%" }}
          >
            {intl.formatMessage({ id: "save_changes" })}
          </Button16Invert>
        </Flex>
      )}
    </div>
  );
};

const Location = ({
  tenantId,
  setComponentToShow,
  location,
  mode,
}: LocationCompType) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { setFieldsValue, resetFields } = form;
  const { fetchDeviceData, fetchDevices } = useDevice();
  const { data: countriesData } = useCountry();
  const {
    addLocation,
    addLocationResponseData,
    updateLocation,
    updateLocationResponseData,
  } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { openNotification } = useContext<any>(NotificationContext);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  useEffect(() => {
    fetchDevices({
      variables: {
        tenantId: tenantId,
        page: { number: 0, size: 999999 },
        filter: [`status:${EntityStatus.ACTIVE}`],
      },
    });
  }, [tenantId, fetchDevices]);

  useEffect(() => {
    if (location) {
      setFieldsValue({
        location: _.get(location, "name", ""),
        country: _.get(location, "country.id", ""),
        latitude: _.get(location, "latitude", undefined),
        longitude: _.get(location, "longitude", undefined),
        sites: _.get(location, "sites", []),
      });
    }
  }, [location, setFieldsValue]);

  useEffect(() => {
    if (addLocationResponseData || updateLocationResponseData) {
      mode === "add" &&
        openNotification({
          type: "success",
          description: intl.formatMessage({ id: "add_location_msg" }),
          placement: "topRight",
        });
      mode === "edit" &&
        openNotification({
          type: "success",
          description: intl.formatMessage({ id: "update_location_msg" }),
          placement: "topRight",
        });
      resetFields();
      setComponentToShow("");
    }
  }, [
    intl,
    addLocationResponseData,
    updateLocationResponseData,
    openNotification,
    resetFields,
    setComponentToShow,
    mode,
  ]);

  const countryOptions = useMemo(
    () => transformCountryOption(_.get(countriesData, "countries", [])),
    [countriesData]
  );

  const handleSubmit = () => {
    const {
      location: name,
      latitude,
      longitude,
      country,
      sites = [],
    } = form.getFieldsValue();

    const addLocationPayload = {
      name,
      latitude: Number(latitude),
      longitude: Number(longitude),
      country: { id: country },
      status: EntityStatus.ACTIVE,
      sites: (sites || []).map((site) => ({
        ...site,
        status: EntityStatus.ACTIVE,
      })),
      tenant: { id: tenantId },
    };

    if (mode === "add")
      addLocation({
        variables: { input: addLocationPayload },
      });
    else if (mode === "edit") {
      const removedSites = _.differenceBy(
        location.sites || [],
        sites || [],
        "id"
      );
      const deactivatedSites = removedSites.map((site) => {
        return { ...site, status: EntityStatus.DEACTIVATED };
      });
      const editLocationPayload = {
        ...addLocationPayload,
        sites: [...addLocationPayload.sites, ...deactivatedSites],
        id: location.id,
      };
      updateLocation({
        variables: { input: editLocationPayload },
      });
    }
  };

  const handleDelete = () => {
    const isAnySiteActive = (location.sites || []).some(
      ({ status }) => status === EntityStatus.ACTIVE
    );

    const locationToDelete = {
      name: location.name,
      latitude: location.latitude,
      longitude: location.longitude,
      country: { id: location.country.id },
      status: EntityStatus.DEACTIVATED,
      tenant: { id: tenantId },
      id: location.id,
      sites: (location.sites || []).map((site) => ({
        ...site,
        status: EntityStatus.DEACTIVATED,
      })),
    };

    isAnySiteActive &&
      openNotification({
        type: "error",
        description: intl.formatMessage({ id: "location_delete_validation" }),
        placement: "topRight",
      });

    !isAnySiteActive &&
      updateLocation({
        variables: { input: locationToDelete },
      });
    setShowDeleteConfirm(false);
  };
  const devices = _.get(fetchDeviceData, "devices.devices", []);
  return (
    <>
      <StyledCard
        style={{ width: 400, position: "absolute", left: 366, top: 70 }}
      >
        <Form
          name="locations"
          form={form}
          onFinish={handleSubmit}
          style={{ padding: "0 16px" }}
          layout="vertical"
        >
          <Header
            intl={intl}
            setComponentToShow={setComponentToShow}
            mode={mode}
          />
          <LocationForm countryOptions={countryOptions} intl={intl} />
          <Divider />
          <SiteForm devices={devices} form={form} intl={intl} />
          <Footer
            mode={mode}
            intl={intl}
            setShowDeleteConfirm={setShowDeleteConfirm}
          />
        </Form>
      </StyledCard>
      <DeleteConfirmModal
        title={intl.formatMessage({ id: "delete" })}
        okText={intl.formatMessage({ id: "delete_btn" })}
        cancelText={intl.formatMessage({ id: "cancel_btn" })}
        content={intl.formatMessage({ id: "delete_location_text" })}
        open={showDeleteConfirm}
        handleOk={handleDelete}
        handleCancel={() => {
          setShowDeleteConfirm(false);
        }}
      />
    </>
  );
};

export default Location;
