import { styled } from "@datwyler/mfe-shared-components";
import { Row } from "antd";

export const ListContainer = styled.div`
  padding: 8px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 8;
`;

export const StyledRow = styled(Row)`
  cursor: pointer;
  padding: 8px 16px 16px 16px;
  border-bottom: 1px solid
    ${(props) => props.theme.datwylerBorder.borderPrimary};
  &:hover {
    background-color: ${(props) =>
      props.theme.datwylerSurface.surfaceSecondary};
  }
`;
