import { useEffect, useState } from "react";
import { Map, styled, useIntl } from "@datwyler/mfe-shared-components";
import Location from "@components/Location";
import LocationsList from "@components/LocationsList";
import SiteList from "@components/SiteList";
import SiteDetails from "@components/SiteDetails";
import useLocations from "@hooks/useLocations";
import { LocationsProps, LocationType, SiteType } from "@types";

const SiteListContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
`;

const Locations = ({ tenantId }: LocationsProps) => {
  const intl = useIntl();
  const {
    locations,
    siteDetails,
    alarmDetails,
    getSiteDetails,
    getAlarmDetails,
  } = useLocations(tenantId);
  const [locationFilter, setLocationFilter] = useState("");
  const [selectedLocation, setSelectedLocation] =
    useState<LocationType | null>();
  const [selectedSite, setSelectedSite] = useState<SiteType | null>();
  const [componentToShow, setComponentToShow] = useState("");

  useEffect(() => {
    setSelectedLocation(null);
    setSelectedSite(null);
    setComponentToShow("");
  }, [locationFilter]);

  useEffect(() => {
    setSelectedSite(null);
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedSite) {
      getSiteDetails(selectedSite.id);
      getAlarmDetails(selectedSite.id);
    }
  }, [getSiteDetails, getAlarmDetails, selectedSite]);

  const onMarkerClick = (location) => {
    setSelectedLocation(location);
    setComponentToShow("site-list");
  };

  const ComponentSwitcher = () => {
    switch (componentToShow) {
      case "site-list":
        return (
          <SiteList
            location={selectedLocation}
            setSelectedSite={setSelectedSite}
            setComponentToShow={setComponentToShow}
            intl={intl}
          />
        );
      case "site-details":
        return (
          <SiteDetails
            tenantId={tenantId}
            siteDetails={{
              devices: siteDetails,
              site: selectedSite,
              location: selectedLocation,
            }}
            alarmDetails={alarmDetails}
            setSelectedSite={setSelectedSite}
            setComponentToShow={setComponentToShow}
            intl={intl}
          />
        );
      case "location-edit":
        return (
          <Location
            tenantId={tenantId}
            setComponentToShow={setComponentToShow}
            location={selectedLocation}
            mode="edit"
          />
        );
      case "location-add":
        return (
          <Location
            tenantId={tenantId}
            setComponentToShow={setComponentToShow}
            mode="add"
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div style={{ position: "relative", height: "100%" }}>
        <Map locations={locations} onMarkerClick={onMarkerClick} />
        <SiteListContainer>
          <LocationsList
            locations={locations}
            locationFilter={locationFilter}
            setLocationFilter={setLocationFilter}
            setSelectedLocation={setSelectedLocation}
            setComponentToShow={setComponentToShow}
            intl={intl}
          />

          <ComponentSwitcher />
        </SiteListContainer>
      </div>
    </>
  );
};

export default Locations;
